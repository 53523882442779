import axios from 'axios';

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

export const FETCH_CALENDAR_BEGIN = 'FETCH_CALENDAR_BEGIN';
export const FETCH_CALENDAR_SUCCESS = 'FETCH_CALENDAR_SUCCESS';
export const FETCH_CALENDAR_FAILURE = 'FETCH_CALENDAR_FAILURE';

export const fetchCalendarBegin = () => ({
  type: FETCH_CALENDAR_BEGIN
});

export const fetchCalendarSuccess = (calendar) => ({
  type: FETCH_CALENDAR_SUCCESS,
  payload: { calendar }
});

export const fetchCalendarFailure = error => ({
  type: FETCH_CALENDAR_FAILURE,
  payload: { error }
});

export function fetchCalendar(calendarUrl) {
  return dispatch => {
    dispatch(fetchCalendarBegin());
    return axios.get(`${BASE_API_URL}/${calendarUrl}.json`, {
    })
      .then(json => {
        dispatch(fetchCalendarSuccess(json.data));
        return json.data;
      })
      .catch(error => dispatch(fetchCalendarFailure(error)));
  };
}
