import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Typography, Calendar, Button, Select } from 'antd';
import styled from 'styled-components'
import { LeftOutlined, RightOutlined, GlobalOutlined } from '@ant-design/icons';
import { setTimezone } from '../actions/timezoneActions';

const { Text } = Typography
const { Option } = Select

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;
`

const AppointmentCalendar = ({ calendar, setDate, slot, noTimezone = false, setDateClicked }) => {
  const dispatch = useDispatch();
  const [activeTimezone, setActiveTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone)
  const [selectedDate, setSelectedDate] = useState(moment())
  const timezones = useSelector(state => state.timezone.items)
  const timezoneLoading = useSelector(state => state.timezone.timezoneLoading)
  const lastAvailableDate = calendar.last_available_date

  const tzOptions = timezones.map((tz, i) => <Option key={`${i}`} value={tz.id}>(GMT{tz.offset}) {tz.name}</Option>)

  useEffect(() => {
    dispatch(setTimezone(activeTimezone))
  }, [dispatch, activeTimezone]);

  useEffect(() => {
    const date = defaultDate()
    setSelectedDate(date);
    setDate(date);
    window.innerWidth < 767 ? setDateClicked(false) : setDateClicked(true);
  }, []);

  const isDisabledDate = (date) => {
    return (moment().isAfter(date) && !moment().isSame(date, 'day')) || (!calendar.available_days.includes(date.day()) || date.isAfter(moment(lastAvailableDate).add(1,'days')))
  }

  const monthAvailable = (value) => {
    return moment(lastAvailableDate).month() === value.month()
  }

  const monthBack = (value, onChange) => {
    const newValue = value.clone();
    newValue.month(value.month() - 1);
    onChange(moment().isAfter(newValue) ? moment() : newValue);
    setDateClicked(false);
  }

  const monthForward = (value, onChange) => {
    const newValue = value.clone();
    newValue.month(value.month() + 1);
    onChange(newValue);
    setDateClicked(false);
  }

  const defaultDate = () => {
    if (calendar.minimum_notice_unit !== 'days') return moment(slot);

    const createdAtAdjustedDate = moment().add(calendar.minimum_notice_value, 'days');
    return findNextAvailableDate(createdAtAdjustedDate);
  };

  const findNextAvailableDate = (date) => {
    if (isDisabledDate(date)) {
      return findNextAvailableDate(date.add(1, 'days'));
    }
    return date;
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    setDate(date);
    setDateClicked(true);
  };

  return (
    <>
      <Calendar
        disabledDate={isDisabledDate}
        value={selectedDate}
        fullscreen={false}
        headerRender={({ value, type, onChange, onTypeChange }) => {
          return (
            <div style={{ width: '100%', display: 'flex' }}>
              <Text style={{ fontSize: 16, marginRight: 'auto' }}>{value.format('MMMM YYYY')}</Text>
              <Button type='text' size='large' icon={<LeftOutlined />} disabled={moment().endOf('month').isAfter(value)} onClick={() => monthBack(value, onChange)} />
              <Button type='text' size='large' icon={<RightOutlined />} disabled={lastAvailableDate ? monthAvailable(value) : false} onClick={() => monthForward(value, onChange)} />
            </div>
          )
        }}
        onSelect={handleDateSelect}
      />

      {!noTimezone &&
        <Wrapper>
          <GlobalOutlined style={{ fontSize: 20 }} />
          <Select
            showSearch
            style={{ width: 800, paddingLeft: 10, zIndex: 9999 }}
            placeholder="Select a time zone"
            optionFilterProp="children"
            loading={timezoneLoading}
            value={activeTimezone}
            onChange={setActiveTimezone}
          >
            {tzOptions}
          </Select>
        </Wrapper>
      }
    </>
  )
}

export default AppointmentCalendar
