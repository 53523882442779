import React from "react";
import ErrorDisplay from "../shared/ErrorDisplay";
import { Form, Input, Button, Typography } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import moment from 'moment';

import {
  createCalendarEvent,
  createCalendarEventClear,
  updateCalendarEvent,
  updateCalendarEventClear,
} from "../actions/calendarEventActions";
import { FormWrapper } from "../shared/styles/FormWrapper";

const { Title } = Typography;

class AppointmentForm extends React.Component {
  handleFinish = (values) => {

    const { calendarUrl } = this.props.match.params;
    const { slot, eventType, event } = this.props;

    values["start_time"] = slot.format();
    values["end_time"] = slot
      .clone()
      .add(eventType.duration, "minutes")
      .format();
    values["calendar_event_type_id"] = eventType.id;

    if (event) {
      this.props.dispatch(
        updateCalendarEvent(calendarUrl, event.event_hash, values)
      );
    } else {
      this.props.dispatch(createCalendarEvent(calendarUrl, values));
    }
  };

  componentDidUpdate = (prevProps) => {
    const { calendarUrl } = this.props.match.params;

    if (
      prevProps.newSuccess !== this.props.newSuccess &&
      this.props.newSuccess
    ) {
      this.props.dispatch(createCalendarEventClear());
      this.props.history.push(
        `/${calendarUrl}/confirm/${this.props.event.event_hash}`
      );
    }

    if (prevProps.success !== this.props.success && this.props.success) {
      this.props.dispatch(updateCalendarEventClear());
      const redirectUrl = this.props.location.search
      this.props.history.push(
        `/${calendarUrl}/confirm/${this.props.event.event_hash}${redirectUrl}`
      );
    }
  };

  render() {
    const { error, loading, event, eventType } = this.props;

    return (
      <FormWrapper>
        <Form
          layout="vertical"
          onFinish={this.handleFinish}
          initialValues={{
            ...event,
            payload: event ? event.payload : eventType.payload,
          }}
        >
          <Title level={4}>Enter Details</Title>
          <ErrorDisplay error={error} />
          <Form.Item label="Name" name={"name"} rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name={"email"}
            rules={[{ required: true, type: "email" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Phone" name={"phone"} rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Additional Information"
            name={"message"}
            rules={[{ required: true }]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.List name={["payload", "questions"]} label="Questions">
            {(fields, opts) => (
              <>
                {fields.map((_field, index) => {
                  const question = eventType.payload.questions[index];
                  return (
                    <>
                      <Form.Item
                        label={question.name}
                        name={[index, "answer"]}
                        rules={[
                          {
                            required: question.required,
                            message: "Please enter a question value",
                          },
                        ]}
                      >
                        <Input type="text" />
                      </Form.Item>
                    </>
                  );
                })}
              </>
            )}
          </Form.List>
          {event && (
            <>
              <Form.Item
                label="Reason for rescheduling"
                name={"rescheduling_reason"}
              >
                <Input.TextArea rows={4} />
              </Form.Item>
              <Form.Item name={"rescheduled_at"} initialValue={moment()} noStyle>
                <Input type="hidden" />
              </Form.Item>
            </>
          )}
          <Button htmlType="submit" type="primary" loading={loading}>
            {event ? 'Reschedule meeting' : 'Schedule meeting'}
          </Button>
        </Form>
      </FormWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  event: state.calendarEvent.event,
  newSuccess: state.calendarEvent.newSuccess,
  loading: state.calendarEvent.loading,
  error: state.calendarEvent.error,
  success: state.calendarEvent.editSuccess,
});

export default connect(mapStateToProps)(withRouter(AppointmentForm));
