import React from "react"
import moment from 'moment'
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { fetchCalendar } from "../actions/calendarActions";
import { Alert, Spin, Row, Col, Divider, Typography } from "antd";
import styled from 'styled-components'
import CalendarSummary from "./CalendarSummary";
import AppointmentCalendar from "./AppointmentCalendar";
import AppointmentSlots from "./AppointmentSlots";
import { fetchAvailableSlots, fetchCalendarEvent } from "../actions/calendarEventActions";
import { fetchTimezones } from '../actions/timezoneActions';
import PageHeader from "../shared/PageHeader";
import { fetchCalendarEventType } from "../actions/calendarEventTypeActions";
import { ColDividerVertical, ColDividerHorizontal } from '../shared/styles/Divider'

const { Title } = Typography

const CalendarWrapper = styled.div`
  max-width: 400px;
  margin: auto;

  @media(min-width: 992px) {
    max-width: 400px;
    margin: auto;
  }

  @media(min-width: 1200px) {
    width: 550px;
  }
`

const CalendarSummaryWrapper = styled.div`
  padding: 20px;
`

const CalendarTitle = styled(Title)`
  text-align: center;

  @media(min-width: 992px) {
    text-align: left;
  }
`

const SlotWrapper = styled.div`
  max-width: 400px;
  margin: auto;

  @media(min-width: 576px) {
    width: 500px;
  }

  @media(min-width: 768px) {
    max-width: 160px;
  }

  @media(min-width: 992px) {
    max-width: 180px;
    margin-left: 30px;
    height: 100px;
  }

  @media(min-width: 1200px) {
    max-width: 217px;
  }
`

const ColWrapper = styled(Col)`
  @media(min-width: 768px) {
    position: relative !important;
  }

  @media(min-width: 768px) {

  }
`

class SelectAppointment extends React.Component {
  state = {
    activeDate: moment(),
    dateClicked: false,
    duration: this.props.match.params.min ? this.props.match.params.min.split('min')[0] : null,
    width: window.innerWidth
  }

  componentDidMount() {
    const { calendarUrl, event_hash, event_slug } = this.props.match.params
    this.props.dispatch(fetchCalendar(calendarUrl))
    this.props.dispatch(fetchTimezones(calendarUrl))

    window.addEventListener("resize", this.update)

    if (event_slug) {
      this.props.dispatch(fetchCalendarEventType(calendarUrl, event_slug))
    }

    if (event_hash) {
      this.props.dispatch(fetchCalendarEvent(calendarUrl, event_hash))
    }
  }

  update = () => {
    this.setState({
      width: window.innerWidth
    });
  };

  componentDidUpdate = (prevProps) => {
    const { calendarUrl } = this.props.match.params
    const { event, eventType } = this.props

    if (prevProps.event !== event && event) {
      const duration = moment.duration(moment(event.end).diff(moment(event.start))).asMinutes()
      this.props.dispatch(fetchAvailableSlots(calendarUrl, duration, moment()))
    }

    if (prevProps.eventType !== eventType && eventType) {
      this.props.dispatch(fetchAvailableSlots(calendarUrl, eventType.duration, moment()))
    }
  }

  setDateClicked = (value) => {
    this.setState({ dateClicked: value })
  }

  setActiveDate = date => {
    const { calendarUrl } = this.props.match.params
    const { eventType } = this.props

    this.setState({ activeDate: date, dateClicked: true })
    this.props.dispatch(fetchAvailableSlots(calendarUrl, eventType.duration, date))
  }

  isDisabledDate = (date) => {
    const { calendar } = this.props
    return (moment().isAfter(date) && !moment().isSame(date, 'day')) || !calendar.available_days.includes(date.day())
  }

  render() {
    const { calendar, loading, slots, slotsLoading, event, eventType, eventTypeLoading } = this.props
    const { activeDate, dateClicked, width } = this.state
    const { event_hash } = this.props.match.params
    let dateTimeColumn = null

    if(width > 767) {
      dateTimeColumn = { padding: 20 }
    } else if(width < 767 && dateClicked && event) {
      dateTimeColumn = { padding: 0, marginTop: 480 }
    } else if(width > 649 && dateClicked){
      dateTimeColumn = { padding: 0, marginTop: 250 }
    } else if(width < 767 && !dateClicked && event) {
      dateTimeColumn = { padding: 0 }
    } else if(width < 767 && dateClicked) {
      dateTimeColumn = { padding: 0, marginTop: 310 }
    } else {
      dateTimeColumn = { padding: 0 }
    }

    const mdColumnWidth = dateClicked ? 16 : 24

    if (loading || eventTypeLoading) {
      return <Spin size='large' style={{ width: '100%', height: '700px', lineHeight: '700px' }} />
    }

    if (calendar === null || calendar === undefined) {
      return (
        <Alert
          message="Calendar not found"
          description="Please make sure you typed the URL correctly"
          type="error"
          showIcon
        />
      )
    }

    if (!event_hash && (eventType === null || eventType === undefined)) {
      return (
        <Alert
          message="Calendar event type not found"
          description="Please make sure you typed the URL correctly"
          type="error"
          showIcon
        />
      )
    }

    if (event_hash && (event === null || event === undefined)) {
      return (
        <Alert
          message="Calendar event not found"
          description="Please make sure you typed the URL correctly"
          type="error"
          showIcon
        />
      )
    }

    if (event_hash && event.cancelled) {
      return (
        < Title level={1} style={{ textAlign: 'center' }}>Sorry, this event has already been canceled.</Title>
      )
    }

    return (
      <Row>
        <PageHeader title={`${calendar.title} - select date and time`} />
        { dateClicked ?
          width > 991 ?
          <>
            <Col xs={24} lg={7}>
              <CalendarSummary calendar={calendar} eventType={eventType} event={event} showBackButton={event === null || dateClicked} rescheduling={event !== null} dateClicked={dateClicked} windowWidth={width} />
            </Col>
            <ColDividerVertical lg={1}><Divider type='vertical' style={{ height: '650px' }} /></ColDividerVertical>
          </>
          :
          <ColWrapper xs={24} lg={7} style={{ position: 'fixed', width: '100%', zIndex: '999', background: '#FFF', top: 0, left: 0 }}>
            <CalendarSummaryWrapper>
              <CalendarSummary calendar={calendar} eventType={eventType} event={event} showBackButton={event === null || dateClicked} rescheduling={event !== null} dateClicked={dateClicked} windowWidth={width} />
            </CalendarSummaryWrapper>

            <ColDividerHorizontal lg={1}><Divider type='horizontal' style={{ width: '100%' }} /></ColDividerHorizontal>
          </ColWrapper>
          :
          <>
            <Col xs={24} lg={7}>
              <CalendarSummary calendar={calendar} eventType={eventType} event={event} showBackButton={event === null || dateClicked} rescheduling={event !== null} dateClicked={dateClicked} windowWidth={width} />
            </Col>

            <ColDividerVertical lg={1}><Divider type='vertical' style={{ height: '700px' }} /></ColDividerVertical>
            <ColDividerHorizontal lg={1}><Divider type='horizontal' style={{ width: '100%' }} /></ColDividerHorizontal>
          </>
        }

        <Col xs={24} lg={16} xl={16} style={dateTimeColumn}>
          <CalendarTitle level={3}>Select a Date & Time </CalendarTitle>
          <Row>
            {width < 767 ?
              <Col xs={24} sm={24} lg={24} xl={16}>
                <CalendarWrapper style={{ width: '100%'}}>
                  { !dateClicked && <AppointmentCalendar calendar={calendar} setDate={this.setActiveDate} slot={this.props.slots[0]} noTimezone={true} setDateClicked={this.setDateClicked} />
                  }
                </CalendarWrapper>
              </Col>
              :
              <Col xs={24} sm={24} md={mdColumnWidth} lg={16} xl={15}>
                <CalendarWrapper>
                  <AppointmentCalendar calendar={calendar} setDate={this.setActiveDate} slot={this.props.slots[0]} setDateClicked={this.setDateClicked} />
                </CalendarWrapper>
              </Col>
            }
            <Col xs={24} md={8} lg={6} xl={8}>
              { dateClicked &&
                <SlotWrapper>
                  {this.isDisabledDate(activeDate) ?
                    <Alert
                      message="No slots available"
                      description="Please select another day."
                      type="warning"
                      showIcon
                    />
                    :
                    !slotsLoading && <AppointmentSlots slots={slots} calendarUrl={calendar.slug} eventType={eventType} eventHash={event_hash} />
                  }
                </SlotWrapper>
              }
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  return {
    calendar: state.calendar.item,
    loading: state.calendar.loading,
    slots: state.calendarEvent.items,
    slotsLoading: state.calendarEvent.loading,
    event: state.calendarEvent.event,
    timezone: state.timezone.item,
    eventType: state.calendarEventType.eventType || state.calendarEvent.event?.event_type,
    eventTypeLoading: state.calendarEventType.loading,
  }
}

export default connect(mapStateToProps)(withRouter(SelectAppointment))
