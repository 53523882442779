import {
  FETCH_AVAILABLE_SLOTS_BEGIN,
  FETCH_AVAILABLE_SLOTS_SUCCESS,
  FETCH_AVAILABLE_SLOTS_FAILURE,
  CREATE_CALENDAR_EVENT_BEGIN,
  CREATE_CALENDAR_EVENT_FAILURE,
  CREATE_CALENDAR_EVENT_SUCCESS,
  CREATE_CALENDAR_EVENT_CLEAR,
  FETCH_CALENDAR_EVENT_BEGIN,
  FETCH_CALENDAR_EVENT_SUCCESS,
  FETCH_CALENDAR_EVENT_FAILURE,
  UPDATE_CALENDAR_EVENT_BEGIN,
  UPDATE_CALENDAR_EVENT_FAILURE,
  UPDATE_CALENDAR_EVENT_SUCCESS,
  UPDATE_CALENDAR_EVENT_CLEAR,
} from '../actions/calendarEventActions';

const initialState = {
  items: [],
  loading: false,
  error: null,
  event: null,
  editSuccess: null,
  newSuccess: null
};

export default function calendarEventReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CALENDAR_EVENT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_CALENDAR_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        event: action.payload.event,
      };

    case FETCH_CALENDAR_EVENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case FETCH_AVAILABLE_SLOTS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_AVAILABLE_SLOTS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.slots.available_slots,
      };

    case FETCH_AVAILABLE_SLOTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case CREATE_CALENDAR_EVENT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case CREATE_CALENDAR_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        newSuccess: true,
        event: action.payload.calendar_event
      };

    case CREATE_CALENDAR_EVENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case CREATE_CALENDAR_EVENT_CLEAR:
      return {
        ...state,
        loading: false,
        event: null,
        error: null,
        newSuccess: null
      };

    case UPDATE_CALENDAR_EVENT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case UPDATE_CALENDAR_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        event: action.payload.calendar_event,
        editSuccess: true,
      };

    case UPDATE_CALENDAR_EVENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case UPDATE_CALENDAR_EVENT_CLEAR:
      return {
        ...state,
        loading: false,
        event: null,
        error: null,
        editSuccess: null,
      };

    default:
      return state;
  }
}
