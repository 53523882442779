import axios from 'axios';

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

export const FETCH_CALENDAR_EVENT_TYPE_BEGIN = 'FETCH_CALENDAR_EVENT_TYPE_BEGIN';
export const FETCH_CALENDAR_EVENT_TYPE_SUCCESS = 'FETCH_CALENDAR_EVENT_TYPE_SUCCESS';
export const FETCH_CALENDAR_EVENT_TYPE_FAILURE = 'FETCH_CALENDAR_EVENT_TYPE_FAILURE';

export const fetchCalendarEventTypeBegin = () => ({
  type: FETCH_CALENDAR_EVENT_TYPE_BEGIN
});

export const fetchCalendarEventTypeSuccess = (event_type) => ({
  type: FETCH_CALENDAR_EVENT_TYPE_SUCCESS,
  payload: { event_type }
});

export const fetchCalendarEventTypeFailure = error => ({
  type: FETCH_CALENDAR_EVENT_TYPE_FAILURE,
  payload: { error }
});

export function fetchCalendarEventType(calendarUrl, event_type_slug) {
  return dispatch => {
    dispatch(fetchCalendarEventTypeBegin());
    return axios.get(`${BASE_API_URL}/${calendarUrl}/event_types/${event_type_slug}.json`, {
    })
      .then(json => {
        dispatch(fetchCalendarEventTypeSuccess(json.data));
        return json.data;
      })
      .catch(error => dispatch(fetchCalendarEventTypeFailure(error)));
  };
}
