import React from 'react';
import ErrorDisplay from '../shared/ErrorDisplay';
import { Form, Input, Button, Typography } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { updateCalendarEvent, updateCalendarEventClear } from '../actions/calendarEventActions';
import { FormWrapper } from '../shared/styles/FormWrapper';

const { Title } = Typography

class AppointmentForm extends React.Component {
  handleFinish = (values) => {
    const { calendarUrl, eventHash } = this.props

    values['op'] = 'cancellation'
    this.props.dispatch(updateCalendarEvent(calendarUrl, eventHash, values))
  }

  componentDidUpdate = (prevProps) => {
    const { calendarUrl, eventHash } = this.props

    if (prevProps.cancelEvent !== this.props.cancelEvent && this.props.cancelEvent) {
      this.props.dispatch(updateCalendarEventClear())
      this.props.history.push(`/${calendarUrl}/cancellations/confirm/${eventHash}`)
    }
  }

  render() {
    const { error, loading } = this.props

    return (
      <FormWrapper>
        <Form layout="vertical" onFinish={this.handleFinish}>
          <Title level={4}>Cancel Event?</Title>
          <ErrorDisplay error={error} />
          <Form.Item label="Reason for canceling" name={'cancellation_reason'} rules={[{ required: true }]}>
            <Input.TextArea rows={5} />
          </Form.Item>
          <Button htmlType="submit" type="primary" loading={loading}>
            Cancel Event
          </Button>
        </Form>
      </FormWrapper>
    )
  }
}

const mapStateToProps = state => ({
  cancelEvent: state.calendarEvent.editSuccess,
  loading: state.calendarEvent.loading,
  error: state.calendarEvent.error
});

export default connect(mapStateToProps)(withRouter(AppointmentForm))
