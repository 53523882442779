import axios from 'axios';
import * as momentTz from 'moment-timezone'

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

export const FETCH_TIMEZONES_BEGIN   = 'FETCH_TIMEZONES_BEGIN';
export const FETCH_TIMEZONES_SUCCESS = 'FETCH_TIMEZONES_SUCCESS';
export const FETCH_TIMEZONES_FAILURE = 'FETCH_TIMEZONES_FAILURE';
export const SET_TIMEZONE_SUCCESS    = 'SET_TIMEZONE_SUCCESS';

export const fetchTimezonesBegin = () => ({
  type: FETCH_TIMEZONES_BEGIN
});

export const fetchTimezonesSuccess = (timezones) => ({
  type: FETCH_TIMEZONES_SUCCESS,
  payload: { timezones }
});

export const fetchTimezonesFailure = error => ({
  type: FETCH_TIMEZONES_FAILURE,
  payload: { error }
});

export const setTimezoneSuccess = timezone => ({
  type: SET_TIMEZONE_SUCCESS,
  payload: { timezone }
});

export function fetchTimezones(slug) {
  return dispatch => {
    dispatch(fetchTimezonesBegin());
    return axios.get(`${BASE_API_URL}/${slug}/timezones.json`, {
      })
      .then(json => {
        dispatch(fetchTimezonesSuccess(json.data.timezones));
        return json.data.timezones;
      })
      .catch(error => dispatch(fetchTimezonesFailure(error)));
  };
}

export function setTimezone(timezone) {
  momentTz.tz.setDefault(timezone)

  return dispatch => {
    dispatch(setTimezoneSuccess(timezone));
  }
}
