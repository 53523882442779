import React from "react"
import styled from 'styled-components'
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { fetchCalendar } from "../actions/calendarActions";
import { Alert, Typography, Spin, Row, Col } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import PageHeader from "../shared/PageHeader";
import { fetchCalendarEvent } from "../actions/calendarEventActions";

const Duration = styled.a`
  display: flex;
  justify-content: center;
  align-items: baseline;
  border: 1px solid transparent;
  border-top: 1px solid rgba(77, 80, 85, 0.1);
  width: 80%;
  min-height: 180px;
  margin: 20px auto;
  padding: 20px;
  transition: border-color 0.1s ease;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
`

const Wrapper = styled.div`
  padding-right: 30px;
  padding-left: 30px;
  max-width: 860px;
  margin-right: auto;
  margin-left: auto;
  padding: 25px 15px;
`

const Arrow = styled.div`
  margin-right: 8px;
  position: relative;
  right: -8px;
  border: 8px solid rgb(0 162 255 / 0%);
  border-left-color: rgb(77, 80, 85);
`

const Text = styled.div`
  flex: 1 1 auto;
  min-width: 240px;
`

const DurationWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 900px;
  margin: 0 auto;
`

const TextWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`

const { Title } = Typography

class CalendarHome extends React.Component {
  componentDidMount() {
    const { calendarUrl, event_hash } = this.props.match.params
    this.props.dispatch(fetchCalendar(calendarUrl))

    if (event_hash) {
      this.props.dispatch(fetchCalendarEvent(calendarUrl, event_hash))
    }
  }

  render() {
    const { event_hash } = this.props.match.params
    const { calendar, loading, eventLoading, event } = this.props

    if (loading || (event_hash && eventLoading)) {
      return <Spin size='large' style={{ width: '100%', height: '700px', lineHeight: '700px' }} />
    }

    if (calendar === null || calendar === undefined) {
      return (
        <Alert
          message="Calendar not found"
          description="Please make sure you typed the URL correctly"
          type="error"
          showIcon
        />
      )
    }

    if (event_hash && (event === null || event === undefined)) {
      return (
        <Alert
          message="Calendar event not found"
          description="Please make sure you typed the URL correctly"
          type="error"
          showIcon
        />
      )
    }

    if (event_hash && event.cancelled) {
      return (
        < Title level={1} style={{ textAlign: 'center' }}>Sorry, this event has already been canceled.</Title>
      )
    }

    return (
      <Wrapper>
        <PageHeader title={`${calendar.title} - select meeting duration`} />
        <Title level={5} style={{ textAlign: 'center' }}>{calendar.title}</Title>
        <Row style={{ marginTop: '30px' }}>
          {calendar.event_types.map((event_type) => (
            <Col xs={24} sm={24} lg={12} key={event_type} style={{ marginBottom: '8px', justifyContent: 'space-around' }}>
              <DurationWrapper>
                <Duration href={event ? `/${calendar.slug}/reschedulings/${event_hash}/${event_type.slug}` : `/${calendar.slug}/${event_type.slug}`}>
                  <TextWrapper>
                    <Avatar style={{ marginRight: 10, height: '20', width: '20' }} />
                    <Text>{event_type.title}</Text>
                    <Arrow />
                  </TextWrapper>
                </Duration>
              </DurationWrapper>
            </Col>
          ))}
        </Row>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  calendar: state.calendar.item,
  loading: state.calendar.loading,
  event: state.calendarEvent.event,
  eventLoading: state.calendarEvent.loading,
});

export default connect(mapStateToProps)(withRouter(CalendarHome))
