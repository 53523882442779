import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router'
import moment from 'moment'
import * as momentTz from 'moment-timezone'
import { Alert, Typography, Button, Space } from 'antd';
import styled from 'styled-components';

const { Text } = Typography

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const TimeWrapper = styled(Text)`
  width: 49%;
  color: #fff;
  background: #00000099;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  cursor: default;
`

const TimeListWrapper = styled(Space)`
  width: 100%;

  @media(min-width: 768px) {
    height: 400px;
    overflow: auto;
  }

  @media(min-width: 992px) {
    height: 500px;
  }
`

const AppointmentSlots = ({ slots, calendarUrl, eventType, eventHash }) => {
  const [activeSlot, setActiveSlot] = useState()
  const history = useHistory()
  const location = useLocation()

  const confirmSlot = (slot) => {
    const time = momentTz(moment(slot))
    const redirectUrl = location.search

    if (eventHash) {
      history.push(`/${calendarUrl}/reschedulings/${eventHash}/${eventType.slug}/${time}${redirectUrl}`)
    } else {
      history.push(`/${calendarUrl}/${eventType.slug}/${time}`)
    }
  }

  const changeTimeSlot = slot => {
    return momentTz(moment(slot)).format('hh:mm a')
  }

  return (
    <Space direction='vertical' style={{ width: '100%' }}>
      <TimeListWrapper direction='vertical'>
      <Text>{moment(slots[0]).format('dddd, MMMM Do')}</Text>
      {slots.length === 0 ?
        <Alert
          message="No slots available"
          description="Please select another day."
          type="warning"
          showIcon
        />
        :
        slots.map((slot) => (
          activeSlot === slot ?
            <ButtonWrapper key={slot}>
              <TimeWrapper>{changeTimeSlot(slot)}</TimeWrapper>
              <Button type='primary' size='large' style={{ width: '49%' }} onClick={(e) => confirmSlot(slot)}>Confirm</Button>
            </ButtonWrapper>
            :
            <Button key={slot} size='large' style={{ width: '100%', color: '#1890ff', border: '#1890ff solid 1px' }} onClick={(e) => setActiveSlot(slot)}>{momentTz(slot).format('hh:mm a')}</Button>
        ))}
      </TimeListWrapper>
    </Space>
  )
}

export default AppointmentSlots
