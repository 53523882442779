import axios from 'axios';

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

export const FETCH_AVAILABLE_SLOTS_BEGIN = 'FETCH_AVAILABLE_SLOTS_BEGIN';
export const FETCH_AVAILABLE_SLOTS_SUCCESS = 'FETCH_AVAILABLE_SLOTS_SUCCESS';
export const FETCH_AVAILABLE_SLOTS_FAILURE = 'FETCH_AVAILABLE_SLOTS_FAILURE';

export const CREATE_CALENDAR_EVENT_BEGIN   = 'CREATE_CALENDAR_EVENT_BEGIN';
export const CREATE_CALENDAR_EVENT_SUCCESS = 'CREATE_CALENDAR_EVENT_SUCCESS';
export const CREATE_CALENDAR_EVENT_FAILURE = 'CREATE_CALENDAR_EVENT_FAILURE';
export const CREATE_CALENDAR_EVENT_CLEAR = 'CREATE_CALENDAR_EVENT_CLEAR';

export const FETCH_CALENDAR_EVENT_BEGIN = 'FETCH_CALENDAR_EVENT_BEGIN';
export const FETCH_CALENDAR_EVENT_SUCCESS = 'FETCH_CALENDAR_EVENT_SUCCESS';
export const FETCH_CALENDAR_EVENT_FAILURE = 'FETCH_CALENDAR_EVENT_FAILURE';

export const UPDATE_CALENDAR_EVENT_BEGIN   = 'UPDATE_CALENDAR_EVENT_BEGIN';
export const UPDATE_CALENDAR_EVENT_SUCCESS = 'UPDATE_CALENDAR_EVENT_SUCCESS';
export const UPDATE_CALENDAR_EVENT_FAILURE = 'UPDATE_CALENDAR_EVENT_FAILURE';
export const UPDATE_CALENDAR_EVENT_CLEAR = 'UPDATE_CALENDAR_EVENT_CLEAR';

export const fetchCalendarEventBegin = () => ({
  type: FETCH_CALENDAR_EVENT_BEGIN
});

export const fetchCalendarEventSuccess = (event) => ({
  type: FETCH_CALENDAR_EVENT_SUCCESS,
  payload: { event }
});

export const fetchCalendarEventFailure = error => ({
  type: FETCH_CALENDAR_EVENT_FAILURE,
  payload: { error }
});

export function fetchCalendarEvent(calendarUrl, eventHash) {
  return dispatch => {
    dispatch(fetchCalendarEventBegin());
    return axios.get(`${BASE_API_URL}/${calendarUrl}/events/${eventHash}.json`, {
    })
      .then(json => {
        dispatch(fetchCalendarEventSuccess(json.data));
        return json.data;
      })
      .catch(error => dispatch(fetchCalendarEventFailure(error)));
  };
}

export const fetchAvailableSlotsBegin = () => ({
  type: FETCH_AVAILABLE_SLOTS_BEGIN
});

export const fetchAvailableSlotsSuccess = (slots) => ({
  type: FETCH_AVAILABLE_SLOTS_SUCCESS,
  payload: { slots }
});

export const fetchAvailableSlotsFailure = error => ({
  type: FETCH_AVAILABLE_SLOTS_FAILURE,
  payload: { error }
});

export function fetchAvailableSlots(calendarUrl, duration, date) {
  return dispatch => {
    dispatch(fetchAvailableSlotsBegin());
    let url = `${BASE_API_URL}/${calendarUrl}/available_slots.json?`
    if (duration) {
      url += `duration=${duration}&`
    }
    if (date) {
      url += `date=${date}&`
    }
    return axios.get(url, {
    })
      .then(json => {
        dispatch(fetchAvailableSlotsSuccess(json.data));
        return json.data;
      })
      .catch(error => dispatch(fetchAvailableSlotsFailure(error)));
  };
}

export const createCalendarEventBegin = () => ({
  type: CREATE_CALENDAR_EVENT_BEGIN
});

export const createCalendarEventSuccess = (calendar_event) => ({
  type: CREATE_CALENDAR_EVENT_SUCCESS,
  payload: { calendar_event }
});

export const createCalendarEventFailure = error => ({
  type: CREATE_CALENDAR_EVENT_FAILURE,
  payload: { error }
});

export const createCalendarEventClear = () => ({
  type: CREATE_CALENDAR_EVENT_CLEAR
});

export function createCalendarEvent(calendarUrl, values) {
  return dispatch => {
    dispatch(createCalendarEventBegin());
    return axios.post(`${BASE_API_URL}/${calendarUrl}/events.json`, 
      JSON.stringify({calendar_event: values}),
      { headers: { 'Content-Type': 'application/json' }}
      )
      .then(json => {
        dispatch(createCalendarEventSuccess(json.data));
        return json.data;
      })
      .catch(error => dispatch(createCalendarEventFailure(error.response.data)));
  };
}

export const updateCalendarEventBegin = () => ({
  type: UPDATE_CALENDAR_EVENT_BEGIN
});

export const updateCalendarEventSuccess = (calendar_event) => ({
  type: UPDATE_CALENDAR_EVENT_SUCCESS,
  payload: { calendar_event }
});

export const updateCalendarEventFailure = error => ({
  type: UPDATE_CALENDAR_EVENT_FAILURE,
  payload: { error }
});

export const updateCalendarEventClear = () => ({
  type: UPDATE_CALENDAR_EVENT_CLEAR
});

export function updateCalendarEvent(calendarUrl, eventHash, values) {
  return dispatch => {
    dispatch(updateCalendarEventBegin());
    return axios.put(`${BASE_API_URL}/${calendarUrl}/events/${eventHash}.json`, 
      JSON.stringify({calendar_event: values}),
      { headers: { 'Content-Type': 'application/json' }}
      )
      .then(json => {
        dispatch(updateCalendarEventSuccess(json.data));
        return json.data;
      })
      .catch(error => dispatch(updateCalendarEventFailure(error.response.data)));
  };
}