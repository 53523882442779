import styled from 'styled-components'

export const FormWrapper = styled.div`
  max-width: 400px;
  margin: auto;

  @media(min-width: 992px) {
    margin-left: 30px;
  }

  @media(min-width: 1200px) {
    max-width: 100%;
    margin-left: 40px;
  }
`
