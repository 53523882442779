import {
  FETCH_TIMEZONES_BEGIN,
  FETCH_TIMEZONES_SUCCESS,
  FETCH_TIMEZONES_FAILURE,
  SET_TIMEZONE_SUCCESS
} from '../actions/timezoneActions';

const initialState = {
  items: [],
  loading: false,
  error: null,
  item: null
};

export default function timezoneReducer(state = initialState, action) {
  switch(action.type) {
    case FETCH_TIMEZONES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_TIMEZONES_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.timezones,
      };

    case FETCH_TIMEZONES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: []
      };

    case SET_TIMEZONE_SUCCESS:
      return {
        ...state,
        item: action.payload.timezone
      };

    default:
      return state;
  }
}
