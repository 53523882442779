import {
  FETCH_CALENDAR_EVENT_TYPE_BEGIN,
  FETCH_CALENDAR_EVENT_TYPE_SUCCESS,
  FETCH_CALENDAR_EVENT_TYPE_FAILURE,
} from '../actions/calendarEventTypeActions';

const initialState = {
  loading: false,
  error: false,
  eventType: null,
};

export default function calendarEventTypeReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CALENDAR_EVENT_TYPE_BEGIN:
      return {
        ...state,
        loading: true,
        error: false
      };

    case FETCH_CALENDAR_EVENT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        eventType: action.payload.event_type,
      };

    case FETCH_CALENDAR_EVENT_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
}
