import React from "react"
import moment from 'moment'
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { fetchCalendar } from "../actions/calendarActions";
import { Alert, Spin, Row, Col, Divider, Typography } from "antd";
import CalendarSummary from "./CalendarSummary";
import AppointmentForm from './AppointmentForm';
import PageHeader from "../shared/PageHeader";
import { fetchCalendarEvent } from "../actions/calendarEventActions";
import { fetchCalendarEventType } from "../actions/calendarEventTypeActions";
import { ColDividerVertical, ColDividerHorizontal } from '../shared/styles/Divider';

const { Title } = Typography

class AppointmentDetails extends React.Component {
  componentDidMount() {
    const { calendarUrl, event_hash, event_slug } = this.props.match.params

    this.props.dispatch(fetchCalendar(calendarUrl))
    
    if (event_slug) {
      this.props.dispatch(fetchCalendarEventType(calendarUrl, event_slug))
    }

    if (event_hash) {
      this.props.dispatch(fetchCalendarEvent(calendarUrl, event_hash))
    }
  }

  render() {
    const { calendar, loading, event, eventType, eventTypeLoading } = this.props
    const { slot, event_hash } = this.props.match.params

    if (loading || eventTypeLoading) {
      return <Spin size='large' style={{ width: '100%', height: '700px', lineHeight: '700px' }} />
    }

    if (calendar === null || calendar === undefined) {
      return (
        <Alert
          message="Calendar not found"
          description="Please make sure you typed the URL correctly"
          type="error"
          showIcon
        />
      )
    }

    if (event_hash && (event === null || event === undefined)) {
      return (
        <Alert
          message="Calendar event not found"
          description="Please make sure you typed the URL correctly"
          type="error"
          showIcon
        />
      )
    }

    if (event_hash && event.cancelled) {
      return (
        < Title level={1} style={{ textAlign: 'center' }}>Sorry, this event has already been canceled.</Title>
      )
    }

    return (
      <Row>
        <PageHeader title={`${calendar.title} - enter meeting information`} />
        <Col xs={24} lg={8} xl={9}>
          <CalendarSummary calendar={calendar} eventType={eventType} slot={moment(slot)} event={event} rescheduling={event !== null} />
        </Col>

        <ColDividerVertical lg={1}><Divider type='vertical' style={{ height: '640px'}}/></ColDividerVertical>
        <ColDividerHorizontal lg={1}><Divider type='horizontal' /></ColDividerHorizontal>

        <Col xs={24} lg={15} xl={10}>
          <AppointmentForm eventType={eventType} slot={moment(slot)} event={event} />
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  calendar: state.calendar.item,
  event: state.calendarEvent.event,
  eventLoading: state.calendarEvent.loading,
  timezone: state.timezone.item,
  eventType: state.calendarEventType.eventType || state.calendarEvent.event?.event_type,
  eventTypeLoading: state.calendarEventType.loading,
});

export default connect(mapStateToProps)(withRouter(AppointmentDetails))
