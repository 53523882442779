import styled from 'styled-components'

export const ColDividerHorizontal = styled.div`
  display: block;
  width: 100%;

  @media(min-width: 992px) {
    display: none;
  }
`
export const ColDividerVertical = styled.div`
  display: none;
  height: 100%;

  @media(min-width: 992px) {
    display: block;
  }
`
