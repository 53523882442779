import React from "react"
import moment from 'moment'
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { fetchCalendar } from "../actions/calendarActions";
import { Alert, Spin, Row, Col, Divider } from "antd";
import CalendarSummary from "./CalendarSummary";
import CancelAppointmentForm from './CancelAppointmentForm';
import PageHeader from "../shared/PageHeader";
import { fetchCalendarEvent } from "../actions/calendarEventActions";
import { ColDividerVertical, ColDividerHorizontal } from '../shared/styles/Divider';

class CancelAppointment extends React.Component {
  componentDidMount() {
    const { calendarUrl, event_hash } = this.props.match.params

    this.props.dispatch(fetchCalendar(calendarUrl))
    this.props.dispatch(fetchCalendarEvent(calendarUrl, event_hash))
  }

  render() {
    const { calendar, loading, event } = this.props

    if (loading) {
      return <Spin size='large' style={{ width: '100%', height: '700px', lineHeight: '700px' }} />
    }

    if (calendar === null || calendar === undefined || event === null || event === undefined) {
      return (
        <Alert
          message="Calendar not found"
          description="Please make sure you typed the URL correctly"
          type="error"
          showIcon
        />
      )
    }

    const duration = moment.duration(moment(event.end).diff(moment(event.start))).asMinutes()

    return (
      <Row>
        <PageHeader title={`${calendar.title} - cancel meeting`} />
        <Col xs={24} lg={9} xl={9}>
          <CalendarSummary calendar={calendar} duration={duration} slot={moment(event.start)} showBackButton={false} />
        </Col>
        <ColDividerVertical lg={1}><Divider type='vertical' style={{ height: '700px'}}/></ColDividerVertical>
        <ColDividerHorizontal lg={1}><Divider type='horizontal' /></ColDividerHorizontal>
        <Col xs={24} lg={12} xl={10}>
          <CancelAppointmentForm calendarUrl={calendar.slug} eventHash={event.event_hash} />
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  calendar: state.calendar.item,
  loading: state.calendar.loading,
  event: state.calendarEvent.event
});

export default connect(mapStateToProps)(withRouter(CancelAppointment))
