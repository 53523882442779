import React from "react"
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom'

import CalendarHome from './CalendarHome'
import SelectAppointment from './SelectAppointment'
import AppointmentDetails from './AppointmentDetails'
import ConfirmAppointment from './ConfirmAppointment'
import CancelAppointment from './CancelAppointment'
import CancellationConfirm from './CancellationConfirm';

class Routes extends React.Component {

  render() {

    return (
      <Router basename="/">
        <Switch>
          <Route exact path="/:calendarUrl" component={CalendarHome} />
          <Route exact path="/:calendarUrl/:event_slug" component={SelectAppointment} />
          <Route exact path="/:calendarUrl/reschedulings/:event_hash" component={SelectAppointment} />
          <Route exact path="/:calendarUrl/reschedulings/:event_hash/:event_slug/:slot" component={AppointmentDetails} />
          <Route exact path="/:calendarUrl/cancellations/confirm/:event_hash" component={CancellationConfirm} />
          <Route exact path="/:calendarUrl/cancellations/:event_hash" component={CancelAppointment} />
          <Route exact path="/:calendarUrl/confirm/:event_hash" component={ConfirmAppointment} />
          <Route exact path="/:calendarUrl/:event_slug/:slot" component={AppointmentDetails} />
        </Switch>
      </Router>
    );
  }
}

export default Routes;
