import React from "react"
import moment from 'moment'
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Alert, Spin, Divider, Typography, Avatar } from "antd";
import { fetchCalendarEvent } from "../actions/calendarEventActions";
import { CalendarOutlined, GlobalOutlined } from "@ant-design/icons";
import { MeetingFlexWrapper, MeetingDetails, ConfirmWrapper } from "../shared/styles/FlexWrapper"

const { Title } = Typography

class CancellationConfirm extends React.Component {
  componentDidMount() {
    const { calendarUrl, event_hash } = this.props.match.params

    this.props.dispatch(fetchCalendarEvent(calendarUrl, event_hash))
  }

  formattedDurationString = duration => {
    if (duration === 60) {
      return '1 Hour meeting'
    } else {
      return `${duration} Minute Meeting`
    }
  }

  render() {
    const { event, loading } = this.props

    if (loading) {
      return <Spin size='large' style={{ width: '100%', height: '700px', lineHeight: '700px' }} />
    }

    if (event === null || event === undefined) {
      return (
        <Alert
          message="Calendar not found"
          description="Please make sure you typed the URL correctly"
          type="error"
          showIcon
        />
      )
    }

    const duration = moment.duration(moment(event.end).diff(moment(event.start))).asMinutes()

    return (
      <ConfirmWrapper>
        <Title style={{ textAlign: 'center' }}>Cancellation Confirmed</Title>
        <Title level={3} style={{ textAlign: 'center', fontWeight: '400' }}>Your meeting with {event.calendar_title} has been cancelled</Title>
        <Divider />
        <MeetingDetails>
          <MeetingFlexWrapper>
            <Avatar style={{ marginRight: 10 }} />
            <Title level={3}>{this.formattedDurationString(duration)}</Title>
          </MeetingFlexWrapper>
          <MeetingFlexWrapper>
            <CalendarOutlined style={{ fontSize: 30, marginRight: 10 }} />
            <Title level={4} delete style={{ maxWidth: 'fit-content' }}>{moment.tz(event.start, event.timezone).format('dddd, MMMM Do h:mm a')} - {moment.tz(event.end, event.timezone).format('h:mm a')}</Title>
          </MeetingFlexWrapper>
          <MeetingFlexWrapper>
            <GlobalOutlined style={{ color: "#aaa", fontSize: 30, marginRight: 10  }} />
            <Title level={4} style={{ color: "#aaa" }} delete>{event.timezone}</Title>
          </MeetingFlexWrapper>
        </MeetingDetails>
      </ConfirmWrapper>
    );
  }
}

const mapStateToProps = state => ({
  event: state.calendarEvent.event,
  loading: state.calendarEvent.loading,
});

export default connect(mapStateToProps)(withRouter(CancellationConfirm))
