import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import * as momentTz from 'moment-timezone'
import { Button, Typography, Select } from 'antd';
import { setTimezone } from '../actions/timezoneActions';
import { ArrowLeftOutlined, ClockCircleFilled, CalendarOutlined, GlobalOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const { Title } = Typography

const Wrapper = styled.div`
  background: #eef6fa;
  padding: 20px 30px;
  margin: auto;
  margin-bottom: 20px;

  @media(min-width: 992px) {
    margin-left: -32px;
    margin-right: -29px;
  }
`

const Detail = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;

  @media(min-width: 992px) {
    text-align: left;
    justify-content: flex-start;
  }
`

const DurationWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: left;

  @media(min-width: 992px) {
    justify-content: flex-start;
  }
`

const SelectWrapper = styled.div`
  margin: 0px auto 0px;
  width: 300px;

  @media(min-width: 992px) {
    margin: 30px auto 20px;
  }
`

const BoxWrapper = styled.div`
  textAlign: left;
  width: 300px;
  display: flex
`

const SummaryWrapper = styled.div`
  @media(min-width: 992px) {
    padding: 20px;
  }
`

const CalendarSummary = ({ calendar, eventType, slot = null, event, showBackButton = true, rescheduling = false, dateClicked, windowWidth, selectedTimezone }) => {
  const dispatch = useDispatch();
  const timezone = useSelector(state => state.timezone.item)
  const [activeTimezone, setActiveTimezone] = useState(timezone || Intl.DateTimeFormat().resolvedOptions().timeZone)
  const timezones = useSelector(state => state.timezone.items)
  const timezoneLoading = useSelector(state => state.timezone.timezoneLoading)
  const { Option } = Select
  const tzOptions = timezones.map((tz, i) => <Option key={`${i}`} value={tz.id}>(GMT{tz.offset}) {tz.name}</Option>)

  useEffect(() => {
    dispatch(setTimezone(activeTimezone))
  }, [dispatch, activeTimezone]);

  return (
    <SummaryWrapper>
      {showBackButton && !event && <a href={slot || (dateClicked && windowWidth < 767) ? `/${calendar.slug}/${eventType?.slug}` : `/${calendar.slug}`}><Button size='large' shape="circle" icon={<ArrowLeftOutlined />} /></a>}
      {showBackButton && event && <a href={ `/${calendar.slug}/reschedulings/${event.event_hash}/` }><Button size='large' shape="circle" icon={<ArrowLeftOutlined />} /></a>}

      <Detail>
        <Title level={5}>{calendar.title}</Title>
        <Title style={{ marginTop: 10, marginBottom: 31 }}>{eventType?.title}</Title>
        <DurationWrapper style={{ marginBottom: 10}}>
          <BoxWrapper>
            <ClockCircleFilled style={{ fontSize: 20, marginRight: 10 }} />
            <Title level={5}>{eventType?.duration} min</Title>
          </BoxWrapper>
        </DurationWrapper>
      </Detail>

      {slot &&
        <>
          <DurationWrapper style={{ marginBottom: 10}}>
            <div style={{ textAlign: 'left', width: '300px', display: 'flex' }}>
              <CalendarOutlined style={{ color: "#06c29c", fontSize: 20, paddingRight: '10px' }} />
              <Title level={5} style={{ color: '#06c29c' }}>
                {slot.format('dddd, MMMM Do')}<br />
                {timezone === null ? `${moment.tz(slot, calendar.timezone).format('h:mm a')} - ${moment.tz(slot.clone().add(eventType?.duration, 'minutes'), calendar.timezone).format('h:mm a')}` : `${momentTz(slot).format('hh:mm a')} - ${momentTz(slot.clone().add(eventType?.duration, 'minutes')).format('h:mm a')}`}
              </Title>
            </div>
          </DurationWrapper>
          <DurationWrapper>
            <BoxWrapper>
              <GlobalOutlined style={{ color: "#aaa", fontSize: 20, paddingRight: '10px' }} />
              <Title level={5} style={{ color: '#aaa' }}>
                {timezone || calendar.timezone}
              </Title>
            </BoxWrapper>
          </DurationWrapper>
        </>
      }

      {rescheduling &&
        <Wrapper>
          <DurationWrapper>
            <BoxWrapper>
              <Title level={5} style={{ color: '#aaa' }}>
                Former Time
              </Title>
            </BoxWrapper>
          </DurationWrapper>
          <DurationWrapper>
            <BoxWrapper>
              <CalendarOutlined style={{ color: '#aaa', fontSize: 20, marginRight: 10 }} />
              <Title level={5} style={{ color: '#aaa' }} delete={slot !== null}>
                {moment.tz(event.start, calendar.timezone).format('dddd, MMMM Do')}<br />
                {moment.tz(event.start, calendar.timezone).format('h:mm a')} - {moment.tz(event.end, calendar.timezone).format('h:mm a')}
              </Title>
            </BoxWrapper>
          </DurationWrapper>
          <DurationWrapper>
            <BoxWrapper>
              <GlobalOutlined style={{ color: "#aaa", fontSize: 20,  marginRight: 10 }} />
              <Title level={5} style={{ color: '#aaa', wordBreak: 'break-word' }} delete={slot !== null}>
                {calendar.timezone}
              </Title>
            </BoxWrapper>
          </DurationWrapper>
        </Wrapper>
      }

      { dateClicked && windowWidth < 768 &&
        <SelectWrapper>
          <GlobalOutlined style={{ fontSize: 20 }} />
          <Select
            showSearch
            style={{ paddingLeft: 10, border: 0, outline: 0, width: 250 }}
            placeholder="Select a time zone"
            optionFilterProp="children"
            loading={timezoneLoading}
            value={activeTimezone}
            onChange={setActiveTimezone}
          >
            {tzOptions}
          </Select>
        </SelectWrapper>
      }
    </SummaryWrapper>
  )
}

export default CalendarSummary
