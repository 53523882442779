import styled from 'styled-components';

export const MeetingFlexWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 15px;
`

export const ConfirmWrapper = styled.div`
  padding: 10px 10px;
  width: 100%;

  @media(min-width: 992px) {
    padding: 50px 150px;
  }
`

export const MeetingDetails = styled.div`
  max-width: 543px;
  margin: auto;

  @media(min-width: 1200px) {
    margin: 0;
  }
`
