import React from "react"
import './App.scss';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux'
import thunk from 'redux-thunk';
import rootReducer from './reducers/rootReducer';
import Routes from './components/Routes'
import { Layout } from 'antd';
import styled from 'styled-components'

const store = createStore(
  rootReducer,
  applyMiddleware(thunk)
);

const CalendarLayout = styled(Layout)`
  background-color: transparent;

  @media(max-width: 768px) {
    padding: 16px;
  }
`

class App extends React.Component {
  render () {
    return (
      <div className="body-container">
        <CalendarLayout>
          <div className='main-app-container'>
            <Provider store={store}>
              <Routes />
            </Provider>
          </div>
        </CalendarLayout>
      </div>
    );
  }
}

export default App
