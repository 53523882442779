import { combineReducers } from "redux";
import calendar from './calendarReducer';
import calendarEvent from './calendarEventReducer';
import timezone from './timezoneReducer';
import calendarEventType from './calendarEventTypeReducer'

export default combineReducers({
  calendar,
  calendarEvent,
  timezone,
  calendarEventType
});
