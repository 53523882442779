import React from "react"
import moment from 'moment'
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Alert, Spin, Divider, Typography, Avatar, Button } from "antd";
import { fetchCalendarEvent } from "../actions/calendarEventActions";
import { CalendarOutlined, LinkOutlined, GlobalOutlined } from "@ant-design/icons";
import { MeetingFlexWrapper, MeetingDetails, ConfirmWrapper } from "../shared/styles/FlexWrapper"

const { Title } = Typography

class ConfirmAppointment extends React.Component {
  componentDidMount() {
    const { calendarUrl, event_hash } = this.props.match.params

    this.props.dispatch(fetchCalendarEvent(calendarUrl, event_hash))
  }

  handleGoBackToList = () => {
    const redirectUrl = this.getRedirectUrl();
    window.location.href = redirectUrl;
  };

  getRedirectUrl = () => {
    const urlParams = new URLSearchParams(this.props.location.search);
    const redirectUrl = urlParams.get('redirect_url');
    return redirectUrl ? redirectUrl : null;
  };

  formattedDurationString = duration => {
    if (duration === 60) {
      return '1 Hour meeting'
    } else {
      return `${duration} Minute Meeting`
    }
  }

  render() {
    const { event, loading } = this.props

    if (loading) {
      return <Spin size='large' style={{ width: '100%', height: '700px', lineHeight: '700px' }} />
    }

    if (event === null || event === undefined) {
      return (
        <Alert
          message="Calendar not found"
          description="Please make sure you typed the URL correctly"
          type="error"
          showIcon
        />
      )
    }

    const duration = moment.duration(moment(event.end).diff(moment(event.start))).asMinutes()

    return (
      <ConfirmWrapper style={{ width: '100%' }}>
        <Title style={{ textAlign: 'center' }}>Confirmed</Title>
        <Title level={3} style={{ textAlign: 'center', fontWeight: '400' }}>You are scheduled with {event.calendar_title}</Title>
        <Divider />
        <MeetingDetails>
          <MeetingFlexWrapper>
            <Avatar style={{ marginRight: 10 }} />
            <Title level={3}>{this.formattedDurationString(duration)}</Title>
          </MeetingFlexWrapper>
          <MeetingFlexWrapper>
            <CalendarOutlined style={{ color: "#06c29c", fontSize: 30, marginRight: 10 }} />
            <Title level={4} style={{ color: '#06c29c', maxWidth: 'fit-content' }}>{moment(event.start).format('dddd, MMMM Do h:mm a')} - {moment(event.end).format('h:mm a')}</Title>
          </MeetingFlexWrapper>
          {event.zoom_link &&
            <MeetingFlexWrapper>
              <LinkOutlined style={{ color: "#40a9ff", fontSize: 30, marginRight: 10 }} />
              <Title level={4} style={{ color: "#40a9ff" }}>{event.zoom_link}</Title>
            </MeetingFlexWrapper>
          }
          <MeetingFlexWrapper>
            <GlobalOutlined style={{ color: "#aaa", fontSize: 30, marginRight: 10 }} />
            <Title level={4} style={{ color: "#aaa" }}>{this.props.timezone}</Title>
          </MeetingFlexWrapper>
        </MeetingDetails>
        <Title level={4} style={{ textAlign: 'center', marginTop: 10 }}>A calendar invitation has been send to your email address</Title>
        {this.getRedirectUrl() && (
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <Button type="primary" onClick={this.handleGoBackToList}>
              Go back to my events
            </Button>
          </div>
        )}
      </ConfirmWrapper>
    );
  }
}

const mapStateToProps = state => ({
  event: state.calendarEvent.event,
  loading: state.calendarEvent.loading,
  timezone: state.timezone.item
});

export default connect(mapStateToProps)(withRouter(ConfirmAppointment))
